@tailwind base;
@tailwind components;
@tailwind utilities;

.grecaptcha-badge { visibility: hidden; }   

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  ::selection {
    color: white;
    background:#7437f8;
  }  


  @keyframes skele {
    0% {background-color: rgba(255, 255, 255, 0);}
    50%  {background-color: rgba(255, 255, 255, 0.082);}
    100% {background-color: rgba(255, 255, 255, 0);}
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.listSelectAnim{
  transition: all 0.2s ease !important;
  transform: scale(100%);
}

.listSelectAnim:hover{
  transform: scale(105%);
}
.gradient-text {
  background: linear-gradient(42deg, #bd094b -60%, #ffa24d 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profileMargin {
  @apply mx-3 xl:ml-[4vw] xl:mx-5
}

.anim-fadein{ 
  animation: fadein 0.3s ease;
}

.text-shadows{
  text-shadow: 0px 0px 2px rgba(0,0,0,0.81)
}

.assetPos {
  object-position: center bottom;
}

.rounded-md-left {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-md-right {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

/* Specifically for the API docs page because i don't wanna css reset, but shouldn't hurt the rest of the site lol */
body {
  margin: 0;
}

.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* @apply hidden */
}
.slides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
  /* @apply hidden */
}
.slides::-webkit-scrollbar-track {
  /* @apply hidden */
}

.hideOnMobile {
  @apply hidden sm:!block
}

.profilePanelHeader {
  @apply m-0 mb-1 text-base font-normal capitalize text-gray-light;
}

/* Google adsense iframe fucking everything up */
/* body > iframe {
  @apply hidden
} */

/* Draft js hack */
.public-DraftStyleDefault-block {
	@apply !my-1;
}

/* AI list gen bg */
.listgenbg {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* Fade in and move down css animation */
.fadeInSlideDown {
  animation: fadeInSlideDownAnim 1.25s ease;
  animation-fill-mode: both;
  @apply transition-all
} 
@keyframes fadeInSlideDownAnim {
  0% {
    transform: translateY(-12px);
    @apply opacity-0;
  
  }

  100% {
    transform: translateY(0px);
    @apply opacity-100;
  }
}

@keyframes hover {
  0%, 100% {
      transform: translateY(-25%);
      /* animation-timing-function: cubic-bezier(0.8,0,1,1); */
  }
  50% {
      transform: none;
      /* animation-timing-function: cubic-bezier(0,0,0.2,1); */
  }
}

